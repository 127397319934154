import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-arag.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/arag-beratungsapp/arag-beratungsapp.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const ARAGBeratungsappSalesApps = function (props, ref) {

    const data = useStaticQuery(graphql`
        query ARAGBeratungsappSalesAppsQuery {
            heropic: file(relativePath: { eq: "images/projekte/arag-beratungsapp/landing/arag-app-og.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/arag-beratungsapp/landing/arag-app-og.jpeg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/arag-beratungsapp/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "iOS App"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="ARAG BeratungsApp"
                description="Q:marketing realisiert Vertriebsapp für die ARAG Versicherung."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>ARAG Beratungsapp</h1>
                        <p className="introtext">Der ARAG Vertrieb überzeugt beim Kunden nicht nur mit innovativen Produkten, sondern auch mit neuer und innovativer Technik.</p>
                        <AnchorLink to="/projekte/sales-apps/arag-beratungsapp/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                              
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Die ARAG BeratungsApp ist ein zentraler Baustein in der Digitalisierung der Geschäftsprozesse unseres Kunden ARAG.",
                        "Sie erleichtert dem Berater die Vorbereitung und Strukturierung seiner Kundengespräche und die Erfassung sämtlicher relevanter Kundeninformationen.",
                        "Die App ermittelt auf Basis von Algorithmen auf die Kundenanforderungen zugeschnittene Produktempfehlungen und erlaubt in Verbindung mit Tarifrechnern den komplett papierlosen Abschluss von Versicherungsverträgen.",
                        "Sie übernimmt automatisiert die komplette rechtssichere Dokumentation des Beratungsgesprächs.",
                    ]}
                />
            </ListenModule>
            
            <Specs data={specsData} />
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default ARAGBeratungsappSalesApps